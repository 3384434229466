import axiosAccounting from '@/libs/axios-accounting'

const getAxios = () => axiosAccounting.get('/costCenters')
const postAxios = data => axiosAccounting.post('/costCenters', data)
const oneAxios = id => axiosAccounting.get(`/costCenters/${id}`)
const searchAxios = id => axiosAccounting.get(`/costCenters/search/${id}`)
const putAxios = (id, data) => axiosAccounting.put(`/costCenters/${id}`, data)
const deleteAxios = id => axiosAccounting.delete(`/costCenters/${id}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  searchAxios,
  putAxios,
  deleteAxios,
}
